.c-svg-library {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
}
