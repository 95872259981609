.c-post-images__item {
  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;
  }

  @include media-breakpoint-up(md) {
    --column-start: 4;
    --column-span: 6;
  }

  .c-post-images--2cols & {
    @include media-breakpoint-up(sm) {
      --column-start: auto;
      --column-span: 6;
    }
  }

  .c-post-images--3cols & {
    @include media-breakpoint-up(sm) {
      --column-start: auto;
      --column-span: 4;
    }
  }

  .c-post-images--full & {
    @include media-breakpoint-up(sm) {
      --column-start: auto;
      --column-span: 12;
    }
  }
}

.c-post-images__media {
  position: relative;
  background: var(--background-color);
}

.c-post-images__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-post-images__caption {
  margin-top: var(--space-xs);
  font-size: var(--step--1);
  line-height: 1.2;
}
