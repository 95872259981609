.c-header-hp {
  --circle-size: calc(var(--space-xl) * 1.1);
  --arrow-size: calc(var(--step-7) * 0.1);

  position: relative;
}

.c-header-hp__content {
  --column-span: 12;

  display: flex;
  flex-direction: column;
  min-height: calc(var(--initial-vh) * 100);
  padding-bottom: var(--space-xl);

  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 3;
  }

  @include media-breakpoint-up(md) {
    --column-span: 6;
    --column-start: 4;

    html:lang(ru) & {
      --column-span: 7;
    }
  }
}

.c-header-hp__title {
  --margin-bottom: calc(var(--circle-size) + var(--container-padding) * 2);
  --padding-spacer: var(--space-xs);

  margin: auto 0 var(--margin-bottom) 0;
  padding: calc(var(--site-header-height) + var(--padding-spacer)) 0 var(--padding-spacer) 0;
  font-size: var(--step-9);
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    --padding-spacer: var(--space-xl);

    margin: auto 0;
  }

  html:lang(ru) & {
    font-size: var(--step-7);
  }
}

.c-header-hp__text {
  @include media-breakpoint-down(sm) {
    margin-bottom: var(--circle-size);
    padding-bottom: var(--container-padding);
  }
}

.c-header-hp__button {
  position: absolute;
  right: var(--container-padding);
  bottom: var(--space-xl);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--circle-size);
  height: var(--circle-size);
  background: none;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  appearance: none;

  &::before {
    width: 0;
    height: 0;
    margin-left: 3px;
    border-top: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid transparent;
    border-left: calc(var(--arrow-size) * 1.7) solid var(--color-white);
    content: '';
  }
}

.c-header-hp__media-wrapper {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: var(--background-color);

  &::before,
  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: rgb(0 0 0 / 30%);
  }

  &::after {
    background: linear-gradient(to top, rgb(0 0 0 / 75%) 0%, rgb(0 0 0 / 0%) 50%);
  }
}

.c-header-hp__video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  transition: opacity 0.3s;
  object-fit: cover;

  .c-header-hp.video-playing & {
    opacity: 1;
  }
}

.c-header-hp__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
