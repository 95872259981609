.c-banner-search {
  --gutter-y: 0;

  padding: var(--banner-space-y) 0;
  background: var(--background-color);

  .c-header-hp + & {
    --flow: 0;
  }

  .dropdown-input {
    outline: none;
  }

  .ts-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  [type='checkbox'] {
    display: none;
  }

  .ts-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ts-dropdown {
    flex-direction: column;
    overflow: hidden;
    margin-top: 0.75rem;
    color: var(--color-black);
    border-top: $form-check-input-border;
    border-radius: $form-input-border-radius;

    .ts-dropdown-content:first-child {
      & > *:first-child {
        margin-top: 10px;
      }
    }

    .ts-dropdown-content {
      & > *:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

.c-banner-search__label {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 3;
  }
}

.c-banner-search__content {
  --column-span: 12;

  @include media-breakpoint-down(sm) {
    margin-top: var(--space-s);
  }

  @include media-breakpoint-up(sm) {
    --column-span: 9;
  }

  @include media-breakpoint-up(md) {
    --column-span: 8;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 7;
  }
}

.c-banner-search__title {
  margin-bottom: 0.1em;
  font-family: var(--font-family-body);
  font-size: var(--step-5);
  font-weight: 300;
  line-height: 1.3;
  text-transform: none;
}

.c-banner-search__button {
  --column-span: 8;
  --column-start: 5;

  @extend %button;

  justify-content: center;
  margin-top: var(--space-xl);

  @include media-breakpoint-up(sm) {
    --column-span: 3;
    --column-start: 9;
  }

  @include media-breakpoint-up(md) {
    --column-span: 2;
  }

  @include media-breakpoint-up(lg) {
    --column-start: 8;
  }
}
