.c-gallery {
  --gutter-y: 0;

  .swiper {
    overflow: visible;
    margin-right: calc(-1 * var(--container-padding));
  }

  .swiper-slide {
    width: auto;

    &:not(:last-child) {
      padding-right: var(--container-padding);

      @include media-breakpoint-up(sm) {
        padding-right: var(--gutter-x);
      }
    }

    &:last-child {
      margin-right: var(--container-padding);
    }
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }
}

.c-gallery__title-wrapper {
  --column-span: 12;

  position: relative;
  z-index: 5;
  display: flex;
  height: 100%;
  margin-right: calc(var(--gutter-x) * -1);

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    right: 0;
    bottom: -1px;
    width: 100vw;
    background: var(--color-black);
    content: '';
  }

  .o-title-sup {
    align-self: flex-end;
  }
}

.c-gallery__container {
  --column-span: 12;
  --index: 2;

  margin-top: var(--space-section-small);

  @include media-breakpoint-up(md) {
    --column-span: 9;

    margin-top: 0;
  }
}

.c-gallery__figure {
}

.c-gallery__image {
  width: auto;
  height: 50vw;

  @include media-breakpoint-up(sm) {
    height: 50vh;
  }
}
