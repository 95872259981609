.c-images__wrapper {
  grid-auto-flow: dense;
}

.c-images__figure {
  @include media-breakpoint-up(sm) {
    &:nth-child(2) {
      --column-span: 4 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(1) {
      --column-span: 6 !important;
      --column-start: 7 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    &:only-child {
      --column-span: 12 !important;
      --column-start: 1 !important;
    }
  }

  &:nth-child(1) {
    --column-span: 7;
    --column-start: 6;
  }

  &:nth-child(2) {
    --column-span: 5;
  }
}
