.c-search {
  margin-top: var(--site-header-height);
  padding-top: var(--space-xl);

  .dropdown-input {
    outline: none;
  }

  .ts-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer !important;

    * {
      pointer-events: none;
    }

    input {
      cursor: pointer;
    }
  }

  [type='checkbox'] {
    display: none;
  }

  .ts-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ts-dropdown {
    position: relative;
    flex-direction: column;
    overflow: hidden;
    margin-top: 0.75rem;
    color: var(--color-black);
    border: none;
    border-radius: $form-input-border-radius;

    &::after {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #fff;
      border-radius: $form-input-border-radius;
      opacity: 0;
      content: '';
      pointer-events: none;
    }

    &::before {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid var(--color-dark-blue);
      border-radius: $form-input-border-radius;
      content: '';
      pointer-events: none;
    }
  }

  &.is-blocked .ts-dropdown::after {
    opacity: 0.75;
    pointer-events: auto;
  }

  .ts-dropdown-content:first-child {
    & > *:first-child {
      margin-top: 10px;
    }
  }

  .ts-dropdown-content {
    & > *:last-child {
      margin-bottom: 10px;
    }
  }
}

.c-search__heading-text {
  position: relative;
  margin-bottom: 0.1em;
  font-family: var(--font-family-body);
  font-size: var(--step-5);
  font-weight: 300;
  line-height: 1.3;
  text-transform: none;

  @include media-breakpoint-up(sm) {
    max-width: 26ch;
  }

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-black);
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  .is-blocked & {
    pointer-events: none;
  }

  .is-blocked &::before {
    opacity: 0.75;
  }
}

.c-search__heading {
  position: relative;
  width: min-content;
  max-width: 100%;

  .ts-dropdown,
  .ts-control,
  .ts-control input {
    font-size: var(--step-1);
  }

  .ts-dropdown-content {
    max-height: calc(var(--step-1) * 9);
  }

  &::before {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-black);
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  .is-blocked & {
    pointer-events: none;
  }

  .is-blocked &::before {
    opacity: 0.75;
  }
}

.c-search__heading-select {
  position: relative;
  display: flex;
  font-size: var(--step-5);

  // display: flex;
  // width: min-content;
}

.c-search__heading-select-wrapper {
  flex: 1;
  min-width: 0;
}

.c-search__heading-select-text {
  display: block;
  overflow: hidden;
  color: var(--primary-color);
  font-family: var(--body-font-family);
  font-size: inherit;
  font-weight: 300;
  line-height: 1.2;
  text-decoration: underline;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  text-decoration-thickness: 0.045em;
  text-underline-offset: 0.1em;
}

.c-search__caret {
  position: relative;
  display: block;
  width: 1em;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.3em;
    height: 0.3em;
    border: 0.07em solid var(--color-orange);
    border-top: none;
    border-left: none;
    transform: translate(-50%, -50%) rotate(45deg);
    content: '';
  }
}

.c-search__select-grid {
  --column-span: 12;
  --gutter-y: 0;

  position: relative;

  @include media-breakpoint-up(sm) {
    margin-top: var(--space-2xl);
  }

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-black);
    opacity: 0;
    content: '';
    pointer-events: none;

    @include media-breakpoint-down(sm) {
      background: var(--color-dark-orange);
    }
  }

  .is-blocked & {
    pointer-events: none;
  }

  .is-blocked &::before {
    opacity: 0.75;
  }
}

.c-search .dropdown-active .ts-dropdown {
  display: flex !important; // override inline style
}

.c-search__select {
  position: relative;

  .c-search__overlay & {
    --column-span: 12;

    @include media-breakpoint-up(sm) {
      --column-span: 6;
    }

    @include media-breakpoint-up(md) {
      --column-span: 3;
    }

    @include media-breakpoint-up(lg) {
      --column-span: 2;
    }
  }

  select {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  .option {
    position: relative;
    overflow: visible !important; // Override default style
    padding-left: calc(24px) !important; // Override default style

    &::before {
      position: absolute;
      top: 13px;
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      background: #171717;
      border-radius: 50%;
      opacity: 0;
      transform: translateX(-14px);
      content: '';

      .c-search__heading & {
        top: var(--space-s);
      }
    }

    &[aria-disabled='true']:hover {
      background: none !important;
    }

    &.selected::before {
      opacity: 1;
    }

    &:hover {
      background: var(--color-gray-warm) !important;
    }

    &.active {
      background: none;
    }
  }

  .dropdown-input-wrap {
    order: -1;
    margin: 5px;

    input {
      color: var(--color-black);
      background: rgb(0 0 0 / 5%);
      border-radius: calc($form-input-border-radius - 5px);
      box-shadow: inset 0 0 10px rgb(0 0 0 / 15%);

      &::placeholder {
        color: #999;
      }
    }
  }

  .c-search__select-all {
    position: relative;
    padding: 5px 8px 5px 24px;
    cursor: pointer;

    &:hover {
      background: var(--color-gray-warm);
    }

    &::before {
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      background: var(--color-black);
      border-radius: 50%;
      opacity: 1;
      transform: translate(-14px, -50%);
      content: '';
    }
  }

  .has-items .c-search__select-all::before {
    opacity: 0;
  }

  .c-search__select-button {
    pointer-events: none;
    overflow: hidden;
    width: 100%;
    padding: 0.9rem 2.5rem 0.9rem 0.75rem;
    font-size: var(--step--1);
    line-height: $form-check-line-height;
    background: $form-input-background-color;
    background-image: $form-select-indicator-image;
    background-position: $form-select-indicator-position;
    background-repeat: no-repeat;
    background-size: $form-select-indicator-size;
    border: $form-check-input-border;
    border-radius: $form-input-border-radius;
  }

  .c-search__select-text {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.c-search__button {
  --column-span: 8;
  --column-start: 5;

  @extend %button;

  justify-content: center;
  margin-top: var(--space-l);

  @include media-breakpoint-up(md) {
    --column-span: 3;
    --column-start: auto;

    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 2;
  }
}

.c-search__remove-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--space-s) * -1);
  color: var(--color-white);
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    margin-top: calc(var(--space-2xs) * -1);
    margin-right: var(--container-padding);
    margin-left: var(--container-padding);
  }

  &.is-not-empty {
    padding-top: var(--space-l);
  }

  a {
    display: block;
    padding: 0.25em 1em;
    text-decoration: none;
    background: var(--color-orange);
    border: 1px solid var(--color-orange);
    border-radius: 999px;
    pointer-events: auto;

    &[data-removable='false'] {
      pointer-events: none;
      background: var(--color-dark-orange);

      @include media-breakpoint-up(sm) {
        background: var(--color-black);
      }

      .o-icon {
        display: none;
      }
    }

    * {
      pointer-events: none;
    }
  }

  a.remove-all {
    font-weight: 700;
    text-decoration: underline;
    background: none;
    border: none;

    @include media-breakpoint-down(sm) {
      padding-top: var(--space-m);
      padding-left: 0;
    }
  }

  .o-icon {
    width: var(--space-s);
    height: var(--space-s);
    margin-top: -3px;
  }

  li {
    display: flex;
    margin-top: var(--space-s);
    font-size: var(--step--1);

    @include media-breakpoint-down(sm) {
      margin-top: var(--space-2xs);

      &:last-child {
        width: 100%;
        margin-top: 0;
      }
    }

    &:not(:last-child) {
      margin-right: var(--space-xs);
    }
  }
}

.c-search__results {
  padding-top: var(--space-l);
  color: var(--color-dark-gray);
  font-weight: 500;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.c-search__overlay {
  @include media-breakpoint-down(sm) {
    position: fixed;
    z-index: $zindex-modal-filters;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    pointer-events: none;
    transform: translateX(100%);

    .show-search-filters & {
      pointer-events: auto;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(0 0 0 / 30%);
      content: '';
      opacity: 0;
      transition: opacity 0.3s;
      transform: translateX(-100%);

      .show-search-filters & {
        opacity: 1;
      }
    }
  }
}

.c-search__overlay-inner {
  @include media-breakpoint-down(sm) {
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100% - var(--space-xl));
    height: 100%;
    margin-left: auto;
    background: var(--color-dark-orange);
    transition: transform 0.3s;
    transform: none;

    .show-search-filters & {
      transform: translateX(calc(-100% - var(--space-xl)));
    }
  }
}

.c-search__overlay-header {
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: space-between;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.c-search__overlay-title {
  padding: var(--container-padding);
  font-family: var(--body-font-family);
  text-transform: none;
}

.c-search__overlay-close {
  padding: var(--container-padding);
  font-weight: 500;
  background: none;
  border: 0;
  appearance: none;
}

.c-search__overlay-main {
  @include media-breakpoint-down(sm) {
    flex-grow: 2;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid rgb(255 255 255 / 20%);
  }

  .c-search__select {
    @include media-breakpoint-down(sm) {
      --column-span: 12;
    }
  }

  .c-search__select-button {
    @include media-breakpoint-down(sm) {
      padding-left: var(--container-padding);
      font-size: var(--step-0);
      font-weight: 500;
      background-position: right var(--container-padding) center;
      border-width: 0 0 1px;
      border-color: rgb(255 255 255 / 20%);
      border-radius: 0;
    }
  }
}

.c-search__overlay-footer {
  position: relative;
  display: flex;
  border-top: 1px solid rgb(255 255 255 / 20%);

  @include media-breakpoint-up(sm) {
    display: none;
  }

  button {
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      margin: var(--container-padding);
      padding: 1.1em 1em;
      color: #000;
      font-size: var(--step-0);
      line-height: 1;
      background: var(--color-white);
      border: none;
      border-radius: 8px;
      appearance: none;
    }
  }

  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-dark-orange);
    opacity: 0;
    content: '';
    pointer-events: none;
  }

  .is-blocked &::after {
    opacity: 0.75;
  }

  .is-blocked & {
    pointer-events: none;
  }
}

.c-search__total {
  --column-span: 3;

  display: flex;
  align-items: center;
  color: var(--color-dark-gray);
  font-size: var(--step--1);
  font-weight: 500;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.c-search__toggle-filter {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: var(--space-l);
    padding: 1.1em 1em;
    color: var(--color-black);
    font-size: var(--step-0);
    line-height: 1;
    background: var(--color-white);
    border: none;
    border-radius: 8px;
    appearance: none;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
