.c-image__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% * 9 / 16);
  background: var(--background-color);

  @include media-breakpoint-up(lg) {
    padding-bottom: calc(100% * 1 / 2.5);
  }
}

.c-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
