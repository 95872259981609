.c-posts-list {
  --gutter-y: var(--space-xl-2xl);
}

.c-posts-list__item {
  display: flex;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
  }
}
