.c-intro {
  pointer-events: none;
  position: fixed;
  z-index: $zindex-site-intro;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c-intro__curtain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-orange);
}

.c-intro__stage {
  width: 12.5vw;
  min-width: 180px;
  max-width: 20rem;
}
