.c-people {
  ul {
    list-style: none;
  }
}

.o-container {
  position: relative;
}

.c-people__grid {
  --gutter-y: var(--space-2xl);

  margin-top: var(--space-2xl);
}

.c-people__grid-item {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 4;

    &:first-child {
      --column-start: 5;
    }
  }

  @include media-breakpoint-up(lg) {
    --column-span: 3;

    &:first-child {
      --column-start: 7;
    }

    &:nth-child(3n+3) {
      --column-start: 4;
    }
  }
}

.c-people__item-name {
  margin-top: var(--space-s);
  font-size: var(--step-2);
}

.c-people__item-figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% * 4 / 3);
  background: var(--background-color);
}

.c-people__item-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
