.c-search-order {
  margin-top: var(--flow);
  font-size: var(--step--1);
  user-select: none;

  &.is-blocked {
    pointer-events: none;
    opacity: 0.3;
  }

  .c-alert + & {
    display: none;
  }

  ul {
    --border-radius: 7px;

    position: absolute;
    z-index: $zindex-modal-filters;
    top: var(--space-m);
    right: calc(var(--border-radius) * -1);
    display: none;
    padding: var(--space-2xs) var(--space-l) var(--space-2xs) var(--space-s);
    text-align: left;
    background: var(--color-orange);
    border-radius: var(--border-radius);
    list-style: none;

    li {
      text-underline-offset: 2px;
      line-height: 1.75;
      cursor: pointer;

      &.is-active {
        text-decoration: underline;
      }
    }
  }

  &.show-search-order ul {
    display: block;
  }

  .o-container {
    text-align: right;
  }
}

.c-search-order__wrapper {
  position: relative;

  @include media-breakpoint-up(lg) {
    --column-span: 10;
    --column-start: 2;
  }
}

.c-search-order__title {
  span {
    margin-left: var(--space-3xs);
    font-weight: 700;
  }

  .o-icon {
    position: relative;
  }
}
