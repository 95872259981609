.c-properties-highlight {
  overflow: hidden;

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.c-properties-highlight__title {
  --column-span: 5;

  display: flex;

  @include media-breakpoint-up(sm) {
    --column-start: 3;
    --column-span: 4;
  }

  @include media-breakpoint-up(md) {
    --column-start: 4;
    --column-span: 3;
  }
}

.c-properties-highlight__list {
  margin-top: var(--space-xl-3xl);
}

.c-properties-highlight__list-item {
  position: relative;
  padding: var(--space-l) 0;
  border-bottom: 1px solid var(--color-white);

  @include media-breakpoint-down(sm) {
    --gutter-y: var(--space-xs);
  }

  li:first-child & {
    border-top: 1px solid var(--color-white);
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    bottom: -1px;
    left: 50%;
    width: 101vw;
    background: var(--color-white);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier('easeOutQuart');
    transform: translateX(-50%);
    content: '';
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-black);
      cursor: pointer;

      &::before {
        opacity: 1;
      }
    }
  }
}

.c-properties-highlight__content {
  --column-span: 12;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    --column-start: 5;
    --column-span: 8;
  }

  @include media-breakpoint-up(md) {
    --column-start: 6;
    --column-span: 7;
  }
}

.c-properties-highlight__heading-title {
  margin-bottom: var(--space-xs);
  font-size: var(--step-3);
  font-weight: 300;
  line-height: 1.2;

  @include media-breakpoint-up(sm) {
    margin-bottom: var(--space-3xs);
  }

  .c-properties-highlight__list-item:hover & {
    color: var(--primary-color);
  }
}

.c-properties-highlight__content-info {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-top: var(--space-s);
  }

  li {
    @include media-breakpoint-down(sm) {
      margin-top: 0.25rem;
    }

    &:not(:last-child) {
      margin-right: var(--space-xs);
    }
  }
}

.c-properties-highlight__numbers-wrapper {
  display: flex;
}

.c-properties-highlight__words-wrapper {
  @include media-breakpoint-up(sm) {
    display: flex;
    margin-left: auto;
  }
}

.c-properties-highlight__figure {
  --column-span: 12;

  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% / 1.5);
  background: var(--background-color);

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }
}

.c-properties-highlight__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.01);
  transition: transform 2s, opacity 0.3s;

  a:hover & {
    transform: scale(1.05);
  }
}

.c-properties-highlight__cta {
  display: flex;
  justify-content: center;

  .o-button {
    margin-top: calc(var(--flow) * 0.5);
  }
}
