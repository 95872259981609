:root {
  --body-font-family: #{$font-family-body};
  --heading-font-family: #{$font-family-heading};
  --heading-line-height: #{$line-height-heading};
  --banner-line-height: #{$line-height-banner};
  --container-margin: 0%;
  --primary-color: var(--color-orange);
  --actual-vh: 1vh;
  --initial-vh: 1vh;
  --underline-offset: 0.4em;
  --space-section-main: var(--space-2xl-4xl);
  --space-section-small: var(--space-m-2xl);
  --banner-space-y: calc(var(--space-section-main) / 2);
  --background-color: var(--color-gray);
  --logo-height: var(--space-xl);
  --logo-width: #{calc(var(--logo-height) * $logo-width / $logo-height)};
  --site-header-padding: var(--space-m-l);
  --site-header-height: calc(var(--logo-height) + var(--site-header-padding) * 2);
  --image-radius: 25px;

  @include media-breakpoint-down(sm) {
    --banner-space-y: calc(var(--space-section-main) / 1.5);
  }

  &:lang(ru) {
    --heading-font-family: #{$font-family-heading-ru};
    --body-font-family: #{$font-family-body-ru};
  }

  @media (min-width: #{$fluid-max-width}px) {
    --container-margin: calc((100vw - #{$fluid-max-width}px) / 2);
  }
}
