.c-property-cards {
  ul {
    list-style: none;
  }

  .c-search + & {
    margin-top: var(--space-xl);
  }

  .c-dashboard & {
    margin-top: var(--space-xl-2xl);
    padding-top: var(--space-xl-2xl);
    padding-bottom: var(--flow);
    background-color: var(--color-gray);
  }
}

.c-property-cards__title-wrapper {
  --column-span: 12;

  @include media-breakpoint-up(lg) {
    --column-start: 2;
  }
}

.c-property-cards__grid {
  --gutter-y: var(--space-2xl-3xl);

  margin-top: var(--space-xl-3xl);
}

.c-property-cards__item {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 6;

    &:nth-child(2n + 1) {
      margin-right: var(--space-xs-m);
    }

    &:nth-child(2n + 2) {
      margin-left: var(--space-xs-m);
    }
  }

  @include media-breakpoint-up(lg) {
    --column-span: 5;

    &:nth-child(2n + 1) {
      --column-start: 2;
    }
  }
}
