@mixin solidHeader() {
  .c-site-header--not-top &,
  .header-solid & {
    @content;
  }
}

.c-site-header {
  --margin-nav: var(--space-s);

  position: absolute;
  z-index: $zindex-site-header;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--site-header-padding) 0;

  @include media-breakpoint-down(md) {
    li {
      opacity: 0;
      transition: opacity 0.2s 0.001s, transform 0.001s 0.2s;
      transform: translateY(1em);

      .show-menu & {
        opacity: 1;
        transition: opacity 0.75s, transform 0.75s;
        transition-delay: calc(var(--index-delay) * 0.05s);
        transform: none;
      }
    }
  }

  html:lang(ru) & {
    --margin-nav: var(--space-xs-l);
  }

  .show-menu & {
    position: fixed !important;
    transform: none !important;
    transition: none !important;
  }

  .o-container {
    position: relative;
  }

  .is-mobile & {
    position: fixed;
    transition: transform 0.4s;
    transform: translate3d(0, 0, 0);

    &.c-site-header--unpinned {
      transform: translate3d(0, -100%, 0);
      pointer-events: none;
    }
  }

  ul {
    display: flex;
    list-style: none;
  }

  button {
    pointer-events: auto;
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-black);
    content: '';
    transform-origin: 0 0;
    transition: transform 0.6s;
    transform: scaleY(0);
  }

  &.c-site-header--not-top::before {
    transform: scaleY(1);
  }
}

.c-site-header__container {
  transition: color 0.2s;

  @include media-breakpoint-up(md) {
    .has-inverted-header & {
      color: var(--color-white);
    }
  }
}

.c-site-header__logo {
  --column-span: 3;

  position: relative;
  z-index: 1;
  display: block;
  width: var(--logo-width);
  height: var(--logo-height);

  @include media-breakpoint-up(lg) {
    --column-span: 3;
  }

  .show-menu & {
    .o-logo {
      fill: var(--color-white) !important;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-site-header__outer-wrapper {
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    pointer-events: none;

    .show-menu & {
      pointer-events: auto;
    }
  }

  @include media-breakpoint-up(md) {
    --column-span: 9;

    display: flex;
  }
}

.c-site-header__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-orange);
  pointer-events: none;
  transform-origin: 0 0;
  opacity: 0;
  will-change: transform;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .show-menu & {
    opacity: 1;
  }
}

.c-site-header__nav-wrapper {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--site-header-height);

  @include media-breakpoint-up(md) {
    margin-top: 0;
    justify-self: center;
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .c-site-header__main-nav a::before {
    @include media-breakpoint-down(md) {
      height: 2px !important;
    }
  }

  a {
    position: relative;
    color: inherit;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      a::before {
        opacity: 1;
      }
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentcolor;
      opacity: 1;
      transition: transform 0.3s;
      content: '';
      transform-origin: 100% 100%;
      transform: scaleX(0);

      @include media-breakpoint-down(md) {
        bottom: 0.1em;
        background: var(--color-white);
      }
    }

    @media (hover: hover) {
      &:hover::before {
        transform: scaleX(1);
        transform-origin: 0 0;
      }
    }
  }

  li.is-active a {
    &::before {
      transform: scaleX(1);
    }
  }
}

.c-site-header__main-nav {
  --column-span: 6;

  @include media-breakpoint-up(md) {
    align-self: center;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 5;
  }

  ul {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      margin-left: var(--container-padding);
    }
  }

  li {
    @include media-breakpoint-up(md) {
      &:not(:first-child) a {
        margin-left: var(--margin-nav);
      }
    }
  }

  a {
    line-height: 1.25;

    @include media-breakpoint-down(md) {
      margin-bottom: 1em;
      font-family: var(--heading-font-family);
      font-size: var(--step-8);
      line-height: 1.4;
      text-transform: none;

      html:lang(ru) & {
        font-size: var(--step-6);
      }
    }
  }
}

.c-site-header__service-nav {
  @include media-breakpoint-down(md) {
    margin-right: var(--container-padding);
  }

  @include media-breakpoint-down(sm) {
    margin-top: var(--space-s);
    padding-bottom: var(--container-padding);
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;

    display: flex;
    align-self: center;
    margin-left: auto;

    & > *:not(:first-child) {
      margin-left: var(--margin-nav);
    }
  }

  .has-inverted-header & {
    fill: var(--color-white);
  }
}

.c-site-header__secondary-nav {
  @include media-breakpoint-up(md) {
    display: flex;
    gap: var(--margin-nav);
  }

  ul {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  li {
    @include media-breakpoint-down(md) {
      align-self: flex-end;
    }

    @include media-breakpoint-up(md) {
      &:not(:last-child) a {
        margin-right: var(--margin-nav);
      }
    }
  }
}

.c-site-header__language {
  display: flex;

  ul {
    @include media-breakpoint-up(md) {
      align-items: center;
    }

    @include media-breakpoint-down(md) {
      margin-top: var(--space-xs);
    }

    @include media-breakpoint-down(sm) {
      margin-left: auto;
    }
  }

  li {
    &:not(:first-child)::before {
      content: '-';
      margin: 0 0.25em;
    }
  }
}

.c-site-header__menu-toggle {
  position: absolute;
  top: 0;
  right: var(--container-padding);
  margin-top: 0.75em;
  font-size: var(--step-0);
  font-weight: 500;
  background: transparent;
  border: none;
  appearance: none;
  transition: color 0.2s;

  @include media-breakpoint-up(md) {
    display: none;
    margin-top: 0.5em;
    font-size: var(--step--1);
  }

  .has-inverted-header & {
    color: var(--color-white);
  }

  *:last-child {
    display: none;
  }

  .show-menu & {
    *:first-child {
      display: none;
    }

    *:last-child {
      display: block;
    }
  }
}
