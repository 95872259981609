.c-property-card {
  color: var(--color-white);

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.c-property-card__content-info {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-xs);
}

.c-property-card__heading-title {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-2xs);
  font-size: var(--step-2);
  line-height: 1.2;
}

.c-property-card__numbers-wrapper {
  display: flex;
  margin-left: auto;

  li:not(:first-child) {
    margin-left: var(--space-xs);
  }
}

.c-property-card__info-code {
  align-self: flex-end;
  margin-right: auto;
  font-size: var(--step--1);
}

.c-property-card__words-wrapper {
  display: flex;
  margin-top: var(--space-xs);
  padding-top: var(--space-xs);
  border-top: 1px solid var(--color-white);

  li:not(:first-child) {
    margin-left: var(--space-xs);
  }
}

.c-property-card__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% / 1.5);
  background: var(--background-color);
}

.c-property-card__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.01);
  transition: transform 2s, opacity 0.3s;

  a:hover & {
    transform: scale(1.05);
  }
}
