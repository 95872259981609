.o-icon {
  display: inline-block;
  flex-shrink: 0;
  width: calc(var(--step-0) * 1.25);
  height: calc(var(--step-0) * 1.25);
  color: currentcolor;
  font-size: 0;
  vertical-align: middle;
  fill: none;
  stroke: currentcolor;
  stroke-width: $icon-stroke-width;
}

.o-icon__svg {
  width: 100%;
  height: 100%;
}

.o-icon--bed,
.o-icon--home,
.o-icon--mark,
.o-icon--bath {
  width: calc(var(--step-1) * 1.25);
  height: calc(var(--step-1) * 1.25);
}

.o-icon--filter {
  stroke-width: 2px;
  stroke-linecap: square;
  width: var(--step--2);
  height: var(--step--2);
}
