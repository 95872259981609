html {
  scroll-behavior: smooth;

  // locomotive scroll
  // &.has-scroll-smooth {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;

  //   [data-scroll-speed] {
  //     will-change: transform;
  //   }
  // }

  &.is-loading {
    *,
    *::after,
    *::before {
      cursor: wait !important;
    }
  }
}

.🏡 {
  // locomotive scroll and body scroll lock
  // &:not(.has-scroll-smooth):not(.body-scroll-locked) {
  //   overflow-y: scroll;
  //   overscroll-behavior-y: none;
  // }

  body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100vh;
    color: var(--color-white);
    background: var(--color-black);
    overscroll-behavior-y: none;

    @media print {
      color: var(--color-black);
      background: #fff;
    }
  }

  &:not(.dom-ready) body {
    opacity: 0;
  }
}

a {
  color: currentcolor;
  outline: none;
}

dfn {
  font-style: normal;
}

abbr {
  text-decoration: none;
}

[data-srcset],
[data-bgset] {
  opacity: 0.01;
  transition: opacity 0.3s;

  &.lazyloaded {
    opacity: 1;
  }
}

[data-bgset] {
  background: center/cover no-repeat;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
