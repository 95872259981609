.c-banner-code {
  --gutter-y: 0;

  padding: calc(var(--banner-space-y) * 1) 0;
  background: var(--color-orange);
}

.c-banner-code__text {
  @extend %banner-text;

  @include media-breakpoint-up(lg) {
    --column-span: 6;
  }
}

.c-banner-code__button {
  --column-span: 12;

  margin-top: var(--space-xl);
  justify-self: end;

  @include media-breakpoint-up(sm) {
    --column-span: 11;

    margin-top: var(--space-m);
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;

    align-self: center;
    margin-top: 0;
    text-align: right;
    justify-self: unset;
  }
}
