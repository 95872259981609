.c-banner-contact {
  --gutter-y: 0;

  padding: var(--banner-space-y) 0;
  background: var(--color-gray);
}

.c-banner-contact__text {
  @extend %banner-text; // Set in _banner-job.scss
}

.c-banner-contact__avatar {
  --column-span: 12;

  display: flex;
  margin-top: calc(var(--space-section-main) / 2);
  margin-left: var(--space-l);

  @include media-breakpoint-up(sm) {
    --column-span: 6;
    --column-start: 7;

    margin-top: var(--space-m);
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
    --column-start: 8;
  }
}

.c-banner-contact__avatar-content {
  align-self: center;
  margin-left: var(--space-s);
}

.c-banner-contact__avatar-name {
  margin-bottom: 0.4em;
  font-size: var(--step-2);
  line-height: 1.2;
}

.c-banner-contact__figure {
  --width: calc(var(--step-9) * 1.4);

  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: var(--width);
  height: calc(var(--width) * 4 / 2.8);
  background: #333;
  border-radius: 999px;

  html:lang(ru) & {
    font-size: calc(var(--step-7) * 1.4);
  }
}

.c-banner-contact__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
