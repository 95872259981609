.c-partners {
  ul {
    list-style: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-partners__title {
  font-size: var(--step-6);

  html:lang(ru) & {
    font-size: var(--step-5);
  }
}

.c-partners__grid {
  --gutter-y: var(--space-xl);

  margin-top: var(--space-l);
}

.c-partners__item {
  --column-span: 6;

  overflow: hidden;
  padding: var(--space-s) var(--space-2xs);
  color: var(--color-orange);
  background: var(--color-white);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }

  @include media-breakpoint-up(xl) {
    --column-span: 2;
  }
}

.c-partners__caption {
  color: var(--color-acquamarine);
  line-height: 1.2;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: var(--step--1);
  }
}

.c-partners__figure {
  position: relative;
  width: 100%;
  height: 0;
  margin: 0 auto;
  margin-top: var(--space-s);
  margin-bottom: var(--space-s);
  padding-bottom: calc(2 / 4) * 100%;

  @include media-breakpoint-up(sm) {
    width: 75%;
    padding-top: 0;
    padding-bottom: calc(2 / 4) * 75%;
  }
}

.c-partners__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
