.c-post-related {
  --flow: var(--space-section-main);
  --gutter-y: var(--space-xl-2xl);
}

.c-post-related__title {
  margin-bottom: var(--space-section-small);
  font-size: var(--step-6);
}

.c-post-related__item {
  display: flex;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
  }

  @include media-breakpoint-down(lg) {
    &:nth-child(n+3) {
      display: none;
    }
  }
}
