.c-banner-form {
  --column-span: 12;
  --gutter-y: 0;

  padding: var(--banner-space-y) 0;
  background: var(--primary-color);

  @include media-breakpoint-up(md) {
    --column-span: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
  }

  &.c-banner-form--gray {
    background: var(--color-gray);
  }
}

.c-banner-form__form {
  --column-span: 12;

  margin-top: var(--space-l);

  @include media-breakpoint-up(md) {
    --column-span: 7;
    --column-start: 6;

    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 7;
  }
}

.c-banner-form__title {
  h3 {
    font-size: var(--step-8);

    html:lang(ru) & {
      font-size: var(--step-6);
    }
  }
}
