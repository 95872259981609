.c-text-highlight__label {
  --column-span: 12;
}

.c-text-highlight__grid {
  --gutter-y: var(--space-m);
}

.c-text-highlight__text {
  --column-span: 12;

  font-size: var(--step-7);
  line-height: var(--banner-line-height);
  text-transform: none;

  @include media-breakpoint-up(sm) {
    --column-span: 11;
  }

  @include media-breakpoint-up(md) {
    --column-span: 10;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 9;

    font-size: var(--step-6);
  }

  html:lang(ru) & {
    font-size: var(--step-5);
  }
}
