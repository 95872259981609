.c-print {
  margin-top: var(--space-s-l);
  margin-bottom: var(--space-s-l);

  ul {
    list-style: none;
  }
}

.c-print__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-xl);
}

.c-print__logo {
  width: 250px;
}

.c-print__button {
  @media print {
    display: none;
  }
}

.c-print__title {
  margin-top: var(--space-s);
  font-size: var(--step-8);
  font-weight: 400;
  text-transform: none;

  html:lang(ru) & {
    font-size: var(--step-6);
  }
}

.c-print__info-heading {
  --column-span: 12;

  display: flex;
  justify-content: space-between;
  margin-top: var(--space-xl);
}

.c-print__image {
  --column-span: 6;

  background: var(--background-color);
}

.c-print__info-price {
  font-weight: 700;
}

.c-print__info-list {

  --column-span: 3;

  font-size: var(--step--1);
  font-weight: 500;
  text-transform: uppercase;
}

.c-print__list-item {
  &:not(:first-child) {
    margin-top: 2px;
  }
}

.c-print__body {
  --column-span: 9;
}
