.c-modal-social {
  position: relative;
  display: none;
  min-width: 20rem;
  border-radius: 7px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .carousel__button {
    position: aboslute !important;
    top: var(--space-2xs) !important;
    right: var(--space-2xs) !important;
    color: var(--color-black) !important;

    svg {
      filter: none !important;
    }
  }
}
