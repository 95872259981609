.c-posts-nav {
  margin-top: var(--space-xl);
  font-weight: 500;
}

.c-posts-nav__dropdown {
  position: relative;

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.c-posts-nav__toggle {
  display: flex;
  width: 100%;
  padding: 0.9rem 2.5rem 0.9rem 0.75rem;
  color: rgb(255 255 255 / 50%);
  font-size: var(--step--1);
  line-height: $form-check-line-height;
  text-transform: uppercase;
  background: $form-input-background-color;
  background-image: $form-select-indicator-image;
  background-position: $form-select-indicator-position;
  background-repeat: no-repeat;
  background-size: $form-select-indicator-size;
  border: $form-check-input-border;
  border-radius: $form-input-border-radius;
  appearance: none;
}

.c-posts-nav__active {
  overflow: hidden;
  margin-left: auto;
  padding-left: 1em;
  color: #fff;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
}

.c-posts-nav__select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.c-posts-nav__tags {
  --index: 2;

  display: flex;
  flex-flow: wrap;
  font-size: var(--step--1);
  gap: var(--space-xs);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.c-posts-nav__tag {
  padding: 0.25em 1em;
  text-decoration: none;
  border: 1px solid var(--color-orange);
  border-radius: 100px;
}

.c-posts-nav__tag--is-active {
  background: var(--color-orange);
}
