.c-alert {
  margin-top: var(--space-xl);
  margin-bottom: calc(var(--space-xl-3xl) * -1 + var(--space-m));
}

.c-alert__text {
  --column-span: 12;

  font-size: var(--step-2);

  @include media-breakpoint-up(lg) {
    --column-span: 10;
    --column-start: 2;
  }
}
