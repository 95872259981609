.c-issuu {
  padding: var(--banner-space-y) 0;
  background: var(--color-gray);

  .swiper {
    margin: 0 calc(var(--gutter-x) * -0.5);
  }

  .swiper-slide {
    padding: 0 calc(var(--gutter-x) * 0.5);

    @include media-breakpoint-up(sm) {
      width: 33.333%;
    }

    @include media-breakpoint-up(md) {
      width: 25%;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }
  }
}

.c-issuu__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-section-small);
}

.c-issuu__headline {
  font-size: var(--step-6);
}

.c-issuu__nav {
  display: flex;
  gap: var(--space-xs);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.c-issuu__prev,
.c-issuu__next {
  display: flex;
  padding: var(--space-2xs);
  background: transparent;
  border: 2px solid var(--color-orange);
  border-radius: 50%;
  appearance: none;
  cursor: pointer;

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.swiper-button-lock {
    display: none;
  }
}

.c-issuu__prev {
  transform: scaleX(-1);
}

.c-issuu__figure {
  position: relative;
  padding-bottom: calc(480 / 360 * 100%);
  background-color: var(--background-color);
}

.c-issuu__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-issuu__link {
  text-decoration: none;

  * {
    pointer-events: none;
  }
}

.c-issuu__title {
  margin-top: 1em;
  line-height: 1.2;
  text-align: center;
}

.c-issuu__pagination {
  transform: none !important;
  margin: var(--space-s) auto 0 !important;

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
