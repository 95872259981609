.c-site-footer {
  --gutter-y: 0;

  padding-top: var(--space-section-main);
  padding-bottom: 2rem;
  font-weight: 500;
  background: #0e0e0e;
  border-top: 1px solid transparent; // for margin top problems

  @include media-breakpoint-down(sm) {
    padding-bottom: var(--container-padding);
  }

  .c-locations--bg + &,
  .c-dashboard + &,
  .c-issuu + & {
    --flow: 0;
  }

  ul {
    list-style-type: none;
  }

  .c-banner-form + & {
    margin-top: 0;
  }

  a {
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.c-site-footer__up {
  margin-top: calc(var(--space-xl) * -1);

  > li > a {
    @extend %heading;
    @extend %measure;

    margin-bottom: var(--space-s);
    font-family: var(--body-font-family);
    font-size: var(--step--1);
    pointer-events: none;
  }

  > li {
    --column-span: 12;

    margin-top: var(--space-xl);

    @include media-breakpoint-up(sm) {
      --column-span: 6;
    }

    @include media-breakpoint-up(md) {
      --column-span: 4;
    }
  }

  li li {
    font-size: var(--step--1);

    &:not(:first-child) a {
      display: block;
      margin-top: var(--space-2xs);
    }
  }
}

.c-site-footer__bottom {
  margin-top: var(--space-section-main);
  font-size: var(--step--1);
}

.c-site-footer__nav {
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    li {
      text-transform: uppercase;

      &:not(:first-child) {
        @include media-breakpoint-down(sm) {
          margin-top: var(--space-2xs);
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.c-site-footer__nav-social {
  li {
    @include media-breakpoint-down(sm) {
      margin-right: 0 !important; //override margin right
      text-align: right;
    }
  }
}

.c-site-footer__service {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-m);
  padding-top: var(--space-m);
  border-top: 1px solid #fff;

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    > *:not(:first-child) {
      margin-top: var(--space-2xs);
    }
  }

  @include media-breakpoint-up(sm) {
    > *:not(:first-child) {
      min-width: 30ch;
      text-align: right;
    }
  }
}
