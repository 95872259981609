.c-account {
  margin-top: var(--site-header-height);
  padding-top: var(--space-xl);

  @include media-breakpoint-up(lg) {
    padding-top: calc(var(--space-xl) * 2);
  }
}

.c-account__panel {
  --flow: var(--space-l);

  @extend %flow;
}

[class*='c-account__panel--'] {
  padding: var(--space-l);
  color: var(--color-white);
  border-radius: var(--space-m);
}

.c-account__panel--orange {
  background-color: var(--color-orange);
}

.c-account__panel--gray {
  background-color: var(--color-gray);
}
