.o-link {
  display: inline-block;
  padding-bottom: 0.25em;
  color: var(--primary-color);
  font-family: var(--body-font-family);
  font-size: var(--step--2);
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid var(--primary-color);
}
