.c-post-header {
  margin-top: var(--site-header-height);
  padding-top: var(--space-xl);

  ~ * {
    --flow: var(--space-section-small);
  }
}

.c-post-header__title {
  --measure: 25ch;

  font-family: var(--body-font-family);
  font-size: var(--step-5);
  font-weight: 300;
  line-height: 1.2;
  text-transform: none;
  text-wrap: balance;

  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;

    order: 1;
  }
}

.c-post-header__categories {
  --index: 2;

  display: flex;
  list-style-type: none;
  gap: 0.6em;

  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;

    order: 3;
  }

  a {
    color: var(--color-orange);
    text-decoration: none;

    .can-hover &:hover {
      text-decoration: underline;
    }
  }
}

.c-post-header__share {
  --index: 2;

  font-size: var(--step--1);
  font-weight: 600;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    --column-start: 1;
    --column-span: 3;

    order: 2;
  }

  a {
    text-decoration: none;

    .can-hover &:hover {
      text-decoration: underline;
    }
  }
}

.c-post-header__cover {
  --index: 3;

  background: var(--background-color);

  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;

    order: 4;
  }
}

.c-post-header__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% / 1.5);
}
