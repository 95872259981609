.c-posts-header {
  margin-top: var(--site-header-height);
  padding-top: var(--space-xl);
}

.c-posts-header__title {
  --measure: 10ch;

  color: var(--color-orange);
  font-size: var(--step-9);
}
