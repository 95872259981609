.c-post-highlight__prose {
  color: var(--color-orange);
  font-size: var(--step-3);
  line-height: 1.2;
  text-align: center;
  text-wrap: balance;

  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;
  }

  @include media-breakpoint-up(md) {
    --column-start: 4;
    --column-span: 6;
  }
}
