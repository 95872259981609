%banner-text {
  --column-span: 12;

  font-size: var(--step-5);
  font-weight: 300;
  line-height: var(--banner-line-height);

  @include media-breakpoint-up(sm) {
    --column-span: 10;
  }

  @include media-breakpoint-up(md) {
    --column-span: 8;
    --column-start: 2;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 7;
    --column-start: 2;
  }
}

.c-banner-job {
  --gutter-y: 0;

  padding: var(--banner-space-y) 0;
  background: var(--color-gray);
}

.c-banner-job__text {
  @extend %banner-text;
}

.c-banner-job__button {
  --column-span: 12;

  margin-top: var(--space-xl);
  justify-self: end;

  @include media-breakpoint-up(sm) {
    --column-span: 11;

    margin-top: var(--space-m);
  }
}
