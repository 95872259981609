.c-header-generic {
  --gutter-y: 0;

  margin-top: var(--site-header-height);
  padding-top: var(--space-m);
}

.c-header-generic__text {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  .c-dashboard & {
    display: flex;
    flex-direction: column-reverse;
  }

  &:only-child {
    @include media-breakpoint-up(sm) {
      --column-span: 12;
    }
  }
}

.c-header-generic__label {
  display: block;
  text-underline-offset: 0.4em;
  text-decoration: none;

  &[href] {
    text-decoration: underline;
  }
}

.c-header-generic__title {
  display: block;
  margin-top: var(--space-s);
  font-size: var(--step-8);
  text-transform: uppercase;
  overflow-wrap: normal;

  @include media-breakpoint-up(sm) {
    margin-top: var(--space-m);
  }

  html:lang(ru) & {
    font-size: var(--step-6);
  }

  em {
    color: var(--primary-color);
    font-style: normal;
  }
}

.c-header-generic__media {
  --column-span: 12;
  --index: 5;

  margin-top: var(--space-section-small);
  margin-right: calc(-1 * (var(--container-padding) + var(--container-margin)));

  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 5;

    margin-top: 0;
  }
}

.c-header-generic__video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  transition: opacity 0.3s;
  object-fit: cover;

  .c-header-generic.video-playing & {
    opacity: 1;
  }
}

.c-header-generic__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% * 3 / 4);
  background: var(--background-color);
  border-radius: 25px 0 0;
}

.c-header-generic__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
