.fancybox__backdrop {
  background: #000;
}

.modal {
  .fancybox__backdrop {
    background: rgb(0 0 0 / 75%);
  }
}

.fancybox__container {
  z-index: $zindex-fancybox;

  * {
    &:focus {
      outline: none !important;
    }
  }
}

.fancybox--embed {
  .fancybox__content {
    padding: 0;
  }
}
