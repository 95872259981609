.o-prose {
  --flow: 1.25em;

  @extend %flow;

  color: inherit;

  ol, ul {
    @extend %flow;

    padding-left: 1.1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: none;
  }

  h4,
  h5,
  h6 {
    font-family: var(--body-font-family);
    line-height: 1.4;
  }

  h6 + h1,
  h6 + h2,
  h6 + h3,
  h6 + h4,
  h6 + h5 {
    --flow: var(--space-m);
  }

  a:not([class]) {
    color: inherit;
  }

  .o-button {
    margin-top: 2em;
  }
}
