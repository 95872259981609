.c-header-property {
  margin-top: var(--site-header-height);
  color: var(--color-white);

  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.c-header-property__content {
  --column-span: 12;
  --index: 3;

  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 3;
  }

  @include media-breakpoint-up(md) {
    --column-span: 6;
    --column-start: 4;
  }
}

.c-header-property__title {
  display: block;
  margin-top: -0.9em;
  font-size: var(--step-9);
  text-align: center;
  text-transform: uppercase;

  html:lang(ru) & {
    font-size: var(--step-7);
  }
}

.c-header-property__subtitle {
  font-size: var(--step-3);
  font-weight: 300;
  text-align: center;
}

.c-header-property__info {
  margin-top: calc(var(--space-section-small) * 0.75);
  padding-top: var(--space-l);
  border-top: 1px solid var(--color-white);

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    border-top: none;
  }
}

.c-header-property__icons {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 3;
  }

  .o-icon {
    position: relative;
    top: -0.35rem;
    margin-right: 0.25rem;
  }

  li {
    &:not(:first-child) {
      margin-top: var(--space-3xs);
    }
  }
}

.c-header-property__cards {
  --column-span: 12;

  display: flex;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  li {
    width: 100%;
    padding: 0.25rem 0.75rem;
    border: 1px solid rgba(#fff, 0.3);
    border-radius: 15px;

    @include media-breakpoint-up(sm) {
      width: 10rem;
    }

    & > p:first-child > span {
      &:first-child {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:last-child {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    p {
      display: block;

      &:last-child {
        font-family: var(--heading-font-family);
        font-size: var(--step-3);
        line-height: 1;
        text-align: right;
      }
    }
  }
}

.c-header-property__info-mq {
  p:last-child {
    abbr {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  p:first-child {
    abbr {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.c-header-property__miscellaneous {
  --column-span: 12;

  text-align: right;

  @include media-breakpoint-up(sm) {
    --column-span: 3;
  }
}

.c-header-property__print,
.c-header-property__share {
  @include media-breakpoint-down(sm) {
    text-decoration: underline;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.c-header-property__figure {
  --column-span: 12;

  position: relative;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: calc((var(--initial-vh) * 80) - var(--site-header-height));
  min-height: 30vw;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: var(--background-color);

  @include media-breakpoint-up(sm) {
    height: calc((var(--initial-vh) * 100) - var(--site-header-height));
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgb(0 0 0 / 75%) 0%, rgb(0 0 0 / 0%) 50%);
    content: '';
  }
}

.c-header-property__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
