/* stylelint-disable selector-class-pattern */
.MarkupPagerNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-section-main);
  font-size: var(--step--1);
  font-weight: 500;
  text-transform: uppercase;
  list-style: none;

  @include media-breakpoint-down(sm) {
    li:not(.MarkupPagerNavPrevious):not(.MarkupPagerNavOn):not(.MarkupPagerNavNext) {
      display: none;
    }
  }

  > * {
    margin: 0 var(--space-3xs);
  }

  a {
    display: block;
    min-width: 3.6em;
    padding: 1em;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: all 0.15s;

    &:hover {
      border: 1px solid currentcolor;
    }
  }

  .MarkupPagerNavOn a {
    border: 1px solid var(--color-orange);
  }
}
