%measure {
  max-width: var(--measure, 100%);
}

%heading {
  display: flex;
  font-family: var(--heading-font-family);
  font-weight: 500;
  line-height: var(--heading-line-height);
  text-transform: uppercase;

  sup {
    align-items: top;
    margin-left: 0.5em;
    color: var(--primary-color);
    font-family: var(--body-font-family);
    font-size: var(--step--1);
    transform: translateY(3%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
  @extend %measure;
}

p {
  @extend %measure;
}

h1 {
  font-size: var(--step-6);

  html:lang(ru) & {
    font-size: var(--step-5);
  }
}

h2 {
  font-size: var(--step-4);
}

h3 {
  font-size: var(--step-3);
}

h4 {
  font-size: var(--step-2);
}

h5 {
  font-size: var(--step-1);
}

h6 {
  font-size: var(--step-0);
}
