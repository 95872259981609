.c-text__body {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 7;
    --column-start: 6;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 7;
  }
}

.c-text__body {
  .truncated + .original {
    display: none;
  }
}
