.c-table__wrapper {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 7;
    --column-start: 6;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 7;
  }
}

.c-table__title {
  margin-bottom: var(--space-xl);
  font-size: var(--step-6);

  html:lang(ru) & {
    font-size: var(--step-5);
  }
}

.c-table__table {
  --index: 2;

  width: 100%;
  border-top: 2px solid var(--color-white);

  tr {
    &:not(:first-child) {
      border-top: 1px solid rgba(255 255 255 / 40%);
    }

    &:last-child {
      border-bottom: 1px solid rgba(255 255 255 / 40%);
    }
  }

  th {
    padding: 0.5rem 1rem 2.5rem 0;
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding: 0.5rem 2.5rem 2.5rem 0;
    }
  }

  td {
    width: 60%;
    padding: 0.5rem 2.5rem 2.5rem 1rem;
    border-left: 1px solid rgba(255 255 255 / 40%);
    vertical-align: top;
  }
}
