.c-banner-property {
  --gutter-y: 0;

  background: var(--primary-color);
}

.c-banner-property__content {
  padding: var(--banner-space-y) 0;
}

.c-banner-property__text {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    --column-span: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 4;
  }
}

.c-banner-property__body {
  margin-top: var(--space-xs);
}

.c-banner-property__button {
  --column-span: 12;

  justify-self: end;
  align-self: center;
  margin-top: calc(var(--space-section-main) / 2);

  @include media-breakpoint-up(sm) {
    --column-span: 6;

    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    --column-span: 7;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 8;
  }
}
