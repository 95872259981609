.o-label {
  font-family: var(--body-font-family);
  font-size: var(--step--1);
  font-weight: 600;
  text-transform: uppercase;
}

.o-label--icon {
  display: flex;
  align-items: center;

  .o-icon {
    position: relative;
    top: -0.15rem;
    margin-right: 0.25rem;
  }
}

.o-label--icon {
  display: flex;
  align-items: center;

  .o-icon {
    position: relative;
    top: -0.15rem;
    margin-right: 0.25rem;
  }
}
