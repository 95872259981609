// Form builder
@import 'form-builder';

// Swiper

@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/modules/pagination/pagination';

// fancybox
@import '../../../node_modules/@fancyapps/ui/src/Fancybox/Fancybox';
@import 'fancybox';

// tom-select
@import 'tom-select';
@import '../../../node_modules/tom-select/dist/scss/tom-select';

// processwire
@import 'processwire';

:root {
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
}
