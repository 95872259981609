.c-video__wrapper {
  position: relative;
  width: 100%;
  padding-bottom: calc(100% * 9 / 16);
  background: var(--background-color);
}

.c-video__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: rgb(0 0 0 / 25%);

  &::before {
    --size: calc(var(--space-xl) * 1.5);

    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: block;
    width: var(--size);
    height: var(--size);
    background: var(--primary-color);
    border-radius: 50%;
    content: '';
    transform: translate(-50%, -50%) scale(1.01);
    transition: transform 0.3s;

    @include media-breakpoint-up(sm) {
      --size: calc(var(--space-xl) * 2);
    }

    .c-video:hover & {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  &::after {
    position: relative;
    z-index: 2;
    transform: translateY(8%);
    color: var(--color-white);
    font-family: var(--heading-font-family);
    font-size: var(--step-4);
    text-transform: uppercase;
    content: 'play';
  }
}

.c-video__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
