@mixin solidHeader() {
  .c-site-header--not-top &,
  .tpl-contact &,
  .header-solid & {
    @content;
  }
}

.o-logo {
  @include solidHeader {
    .has-inverted-header & {
      fill: var(--primary-color);
    }
  }

  overflow: hidden;
  width: 185px;
  height: auto;
  transition: fill 0.2s;
  fill: var(--primary-color);

  .has-inverted-header & {
    fill: var(--color-white);
  }
}
