/* stylelint-disable selector-class-pattern */
.FormBuilder {
  --gutter-x: calc(var(--space-s) * 0.75);

  .InputfieldSubmit {
    margin-top: calc(var(--flow) * 1.5);

    .InputfieldContent {
      text-align: right;
    }

    button {
      @extend %button;
    }
  }
}
