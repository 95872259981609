%button {
  display: inline-flex;
  cursor: pointer;
  padding: 1.25em 2.5em;
  color: var(--color-black);
  font-family: var(--body-font-family);
  font-size: var(--step--1);
  font-weight: 600;
  line-height: 1;
  text-decoration: none !important;
  text-transform: uppercase;
  background: var(--color-white);
  border: none;
  border-radius: 8px;
  outline: none;
  transition: color, background-color, border-color;
  transition-duration: $transition-duration-short;
  appearance: none;

  &.o-button--enhanced.is-loading {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1em;
      height: 1em;
      margin: -0.5em 0 0 -0.5em;
      border: 2px solid currentcolor;
      border-color: transparent currentcolor;
      border-radius: 100%;
      content: '';
      /* stylelint-disable-next-line no-unknown-animations */
      animation: 0.75s infinite linear spin;
    }

    .o-button__wrapper {
      opacity: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-black);
      background: var(--color-white);
      border-color: var(--color-white);
    }
  }
}

.o-button {
  @extend %button;
}

.o-button__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 1em;

  span {
    transition: transform 0.3s cubic-bezier('easeOutQuint');

    &:first-child {
      transition-delay: 0.1s;
    }

    &:last-child {
      transition-delay: 0s;
    }
  }

  @media (hover: hover) {
    .o-button:hover & span,
    button:hover & span {
      transform: translateY(-100%);

      &:first-child {
        transition-delay: 0s;
      }

      &:last-child {
        transition-delay: 0.1s;
      }
    }
  }
}

.o-button--outline-white {
  color: var(--color-white);
  background: transparent;
  border: 1px solid var(--color-white);
}

.o-button--outline-orange {
  color: var(--color-white);
  background: transparent;
  border: 1px solid var(--color-orange);
}
