.c-modal-search {
  position: relative;
  display: none;
  background: var(--color-gray) !important;
  border-radius: 7px;

  @include media-breakpoint-up(sm) {
    min-width: 30rem;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .carousel__button {
    top: var(--space-2xs) !important;
    right: var(--space-2xs) !important;

    svg {
      filter: none !important;
    }
  }

  .c-modal-search__input {
    max-width: 20rem;
    margin-right: auto;
    margin-left: auto;
  }

  .o-button {
    display: flex;
    width: 100%;
    max-width: 20rem;
    margin: var(--space-s) auto;
  }
}

.c-modal-search__title {
  margin-top: var(--space-s);
  margin-bottom: var(--space-xl);
  color: var(--color-white);
  font-family: var(--heading-font-family);
  font-size: var(--step-4);
  font-weight: 500;
  text-align: center;
}
