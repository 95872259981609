.c-header-text {
  margin-top: var(--site-header-height);
  padding-top: var(--space-xl);
  color: var(--color-white);
}

.c-header-text__title {
  --column-span: 12;

  font-size: var(--step-9);
  line-height: var(--banner-line-height);
  text-transform: none;

  @include media-breakpoint-up(sm) {
    --column-span: 10;
  }

  @include media-breakpoint-up(md) {
    --column-span: 9;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 8;
  }

  @include media-breakpoint-up(xl) {
    --column-span: 7;
  }

  html:lang(ru) & {
    font-size: var(--step-7);
  }
}
