.c-post-text__prose {
  @include media-breakpoint-up(sm) {
    --column-start: 4;
    --column-span: 9;
  }

  @include media-breakpoint-up(md) {
    --column-start: 4;
    --column-span: 6;
  }
}
