.c-features {
  color: var(--color-white);

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }

  ul {
    list-style: none;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: auto;
      margin-right: calc(var(--gutter-x) / -2);
      margin-left: calc(var(--gutter-x) / -2);
      transform: none !important;
    }
  }

  .swiper-slide {
    @include media-breakpoint-up(sm) {
      position: relative;
      flex: 0 0 percentage(calc(3 / 9));
      width: 100%;
      max-width: percentage(calc(3 / 9));
      padding-right: calc(var(--gutter-x) / 2);
      padding-left: calc(var(--gutter-x) / 2);
    }

    @include media-breakpoint-down(sm) {
      width: 75%;

      &:not(:last-child) {
        margin-right: var(--container-padding);
      }
    }
  }
}

.c-features__list-wrapper {
  --column-span: 12;

  margin-top: calc(-1 * var(--gutter-x));

  @include media-breakpoint-up(lg) {
    --column-span: 10;
    --column-start: 2;
  }
}

.c-features__item {
  $limit: 12;
  $stagger: 0.05s;

  position: relative;
  width: 100%;
  margin-top: var(--gutter-x);
  padding-bottom: 100%;

  @for $i from 1 through $limit {
    li:nth-child(#{$i}) & {
      --stagger: #{$i};
    }
  }
}

.c-features__circle-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid var(--primary-color);
  border-radius: 100%;
}

.c-features__circle {
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  left: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
}

.c-features__text {
  max-width: 20ch;
  font-family: var(--body-font-family);
  font-size: var(--step--2);
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    font-size: var(--step--1);
  }
}
