.c-about-hp__label {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 5;
  }

  @include media-breakpoint-up(md) {
    --column-span: 6;
  }
}

.c-about-hp__title {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 7;
  }

  @include media-breakpoint-up(md) {
    --column-span: 6;
  }
}

.c-about-hp__media {
  --index: 2;
}

.c-about-hp__figure {
  --column-span: 12;

  position: relative;
  overflow: hidden;
  margin-top: var(--space-section-small);
  margin-right: calc(-1 * var(--container-padding));
  padding-bottom: 100%;
  border-top-left-radius: var(--image-radius);
  background: var(--background-color);

  @include media-breakpoint-up(sm) {
    padding-bottom: 100% * 0.5;
  }
}

.c-about-hp__body-wrapper {
  --index: 3;
}

.c-about-hp__body {
  --column-span: 12;
  --column-start: 1;

  margin-top: var(--space-section-small);

  @include media-breakpoint-up(sm) {
    --column-start: 6;
    --column-span: 7;
  }

  @include media-breakpoint-up(md) {
    --column-start: 7;
    --column-span: 6;
  }
}
