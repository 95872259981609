/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-class-pattern */
.LoginRegisterWrapper {
  @extend .FormBuilder;

  .alert {
    margin-bottom: var(--flow);
    border-radius: 10px;
  }

  h2 {
    margin-bottom: var(--space-l);
  }

  .InputfieldSubmit {
    margin-top: var(--space-s) !important;

    .InputfieldContent {
      text-align: left !important;
    }
  }

  .InputfieldContent {
    .description {
      margin-bottom: 0.5em;
      font-size: 14px;
      font-style: italic;
    }
  }

  .InputfieldPasswordRow + .InputfieldPasswordRow {
    margin-top: var(--flow);
  }

  input[disabled] {
    background: rgb(0 0 0 / 20%);
    opacity: 0.5;
  }
}

.LoginRegisterError {
  color: var(--color-mid-blue) !important;
  font-weight: 700;
}

.LoginRegisterLinks {
  list-style: none;
  margin-top: 2rem;

  > li:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  [class*='c-account__panel--'] & {
    color: #fff;
  }
}

.InputfieldPassword .pass-confirm > span,
.InputfieldPassword .pass-scores > span {
  margin-top: 0.5em;
  padding: 0.1em 0.5em;
  font-size: 0.85em;
  font-weight: 500;
  background: #fff;
  border-radius: 6px;

  &.on {
    display: inline-block !important;
  }
}
