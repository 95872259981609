.c-post-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    pointer-events: none;
  }

  a {
    pointer-events: auto;
  }
}

.c-post-preview__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-post-preview__figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% / 1.5);
  background: var(--background-color);
}

.c-post-preview__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1.01);
  transition: transform 2s, opacity 0.3s;

  .can-hover .c-post-preview__link:hover ~ .c-post-preview__figure & {
    transform: scale(1.05);
  }
}

.c-post-preview__title {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-l);
  font-family: var(--body-font-family);
  font-size: var(--step-2);
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
}

.c-post-preview__meta {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding-top: var(--space-2xs);
  font-size: var(--step--1);
  line-height: 1.2;
  border-top: 1px solid currentcolor;
  gap: 0.6em;
}

.c-post-preview__tag {
  color: var(--color-orange);
  text-decoration: none;

  .can-hover &:hover {
    text-decoration: underline;
  }
}
