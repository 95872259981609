.c-locations {
  &.c-locations--bg {
    padding-top: var(--space-section-main);
    padding-bottom: var(--space-section-main);
    color: var(--color-white);
    background: var(--color-gray);
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.o-container {
  position: relative;
}

.c-locations__title {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: var(--container-padding);
  }
}

.c-locations__grid {
  --gutter-y: var(--space-xl-3xl);

  @include media-breakpoint-down(lg) {
    margin-top: var(--space-xl-3xl);
  }

  @include media-breakpoint-between(sm, lg) {
    .c-locations--indent & {
      margin-top: calc(-1 * var(--step--1) - 7px);
    }
  }
}

.c-locations__grid-item {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 4;

    .c-locations--indent & {
      &:first-child {
        --column-start: 5;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    --column-span: 3;

    &:first-child {
      --column-start: 4;
    }

    .c-locations--indent & {
      &:first-child {
        --column-start: 7;
      }

      &:nth-child(3n+3) {
        --column-start: 4;
      }
    }

    .c-locations:not(.c-locations--indent) & {
      &:nth-child(3n+4) {
        --column-start: 4;
      }
    }
  }
}

.c-locations__item-description {
  margin-top: var(--space-s);
  font-size: var(--step--1);
}

.c-locations__item-figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: var(--space-xs);
  padding-bottom: calc(100% * 4 / 3);
  background: var(--background-color);
}

.c-locations__item-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.01);
  transition: transform 2s cubic-bezier('easeOutQuint'), opacity 0.3s;

  a:hover & {
    transform: scale(1.05);
  }
}
